.sample-table {
  border-collapse: collapse;
  background-color: white;
  width: 100%;

  th {
    height: 30px;
    text-align: left;
    font-size: 0.7rem;
    vertical-align: bottom;
    border-bottom: 1px solid rgb(132, 131, 131);
    padding: 0px 3px;
    font-weight: normal;

    background-color: #5c5c5c;
    color: #ffffff;
  }
  tbody {
    tr {
      &:hover {
        transition: 0.3s;
        background-color: #f2f4f7;
      }
    }
    td {
      // min-width: 40px;
      // font-family: monospace, monospace;
      border-bottom: 1px solid rgb(219, 219, 219);
      font-size: 0.9rem;
      padding: 0px 3px;
      white-space: nowrap;
    }
  }
}

.cylinder-table {
  td {
    font-size: 0.75rem !important;
  }
}
td.cutoff {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
td.cutoff-short {
  max-width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.date-filter {
  display: flex;
  flex-direction: row;
  border: 1px solid rgb(143, 143, 143);
  margin: 0 1rem;
  align-items: center;

  span {
    font-size: 0.75rem;
  }
  input {
    margin: 0px !important;
    padding: 0px;
    border: none;
    font-size: 0.75rem;
  }
  // justify-content: baseline;
}

.cylinder-row {
  a {
    text-decoration: underline;
    color: blue;
  }
  td {
    // font-size: 1rem;
    text-transform: uppercase;
    input {
      font-family: monospace;
      font-size: 0.75rem;
    }
  }
  .cell-input {
    padding: 0 0.15rem !important;
    input {
      height: 25px;
      font-size: 0.85rem;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }
}
.row-complete {
  background-color: rgba(144, 238, 144, 0.616);
}

.cell-red {
  background-color: red;
  font-weight: bold;
}
.cell-input {
  // height: 25px;
  max-width: 40px;
  padding: 0 !important;
  input[type="number"] {
    max-width: 45px;
  }
}
.cell-warning {
  font-weight: bold;
  background-color: rgb(255, 136, 0);
  border: 3px solid orange;
}
