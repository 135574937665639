#root {
  display: flex;
  min-height: 100vh;
}
body {
  min-height: 100vh;
}

.billing-inline-table {
  // width: 500px;
  border-collapse: collapse;
  th {
    text-align: left;
    font-weight: normal;
    padding-left: 2px;
  }
}
$tb_bg: #f8f8f8;
.sample-table-rs {
  [role="columnheader"] {
    background-color: $tb_bg !important;
    color: #787878 !important;
    cursor: default;

    &:hover {
      background-color: $tb_bg !important;
      color: white;
    }
  }
  .rs-table-row-header {
    &:hover {
      background-color: $tb_bg !important;
      color: white;
    }
  }
}

// DELETE

.cylinder-table {
  td {
    font-size: 0.75rem !important;
  }
}
td.cutoff {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
td.cutoff-short {
  max-width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sample-table {
  border-collapse: collapse;
  background-color: white;
  width: 100%;

  th {
    height: 30px;
    text-align: left;
    font-size: 0.7rem;
    vertical-align: bottom;
    border-bottom: 1px solid rgb(132, 131, 131);
    padding: 0px 3px;
    font-weight: normal;

    background-color: #5c5c5c;
    color: #ffffff;
  }
  tbody {
    tr {
      &:hover {
        transition: 0.3s;
        background-color: #f2f4f7;
      }
    }
    td {
      // min-width: 40px;
      // font-family: monospace, monospace;
      border-bottom: 1px solid rgb(219, 219, 219);
      font-size: 0.9rem;
      padding: 0px 3px;
      white-space: nowrap;
    }
  }
}

.cell-red {
  background-color: red;
  font-weight: bold;
}
.cell-input {
  // height: 25px;
  max-width: 40px;
  padding: 0 !important;
  input[type="number"] {
    max-width: 45px;
  }
}
.cell-warning {
  font-weight: bold;
  background-color: rgb(255, 136, 0);
  border: 3px solid orange;
}
.cylinder-row {
  a {
    text-decoration: underline;
    color: blue;
  }
  td {
    // font-size: 1rem;
    text-transform: uppercase;
    input {
      font-family: monospace;
      font-size: 0.75rem;
    }
  }
  .cell-input {
    padding: 0 0.15rem !important;
    input {
      height: 25px;
      font-size: 0.85rem;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }
}

.rs-sidebar {
  height: 100%;
  background: var(--rs-body);
}
.rs-sidenav {
  flex: 1 1 auto;
  width: 100%;
}

.unbilled {
  [role="gridcell"] {
    background-color: rgba(247, 154, 15, 0.356) !important;
    font-weight: bold;
  }

  .rs-table-row-expanded {
    background-color: rgba(224, 224, 224, 0.26) !important;
  }
}
.billed {
  [role="gridcell"] {
    background-color: rgba(96, 209, 20, 0.123) !important;
    opacity: 0.95;
  }
}
